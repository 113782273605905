/*Header Section*/
@media (max-width: 843px){
	.Popup{
		right: 21px !important;
		top: 36px !important;
	}
}
@media (min-width: 768px) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.header .nav-content{
		margin: 0 !important;
		width: 100%;
		display: flex;
		align-items: center;
	}
	header.affix{
		height: 90px;
	}
}
.lanuageOption.open .dropdown-menu.languageMenu{
	height: auto;
}
.header.affix .sub-menu{
	top: 86px;
}
.wishlist i {
	font-size: 22px;
	vertical-align: text-top;
}
/*.sub-menu{
	top: 122px !important;
}*/
/* .header.affix .container-fluid.nav-content{
	margin:18px !important;
} */
/* .header .logo{
	padding-top: 10px;
} */

.header .top-menu{
	padding-right: 0px;
	margin-top: 0px;
}
.menu-item1{
	display: inline-block;
	margin: 0 10px;
}
.dropdowns .menu-item1{
	padding: 0px !important;
}
.dropdown-menu{
	right: 0px !important;
	left: unset;
	opacity: 0.93;
	transition: 0.5s all ease-in-out;
	min-width: 100px;
}
.drop1{
	margin: 0px;
}
.menu-item1.dropdown.lanuageOption a{
	line-height:1.5;
	vertical-align: middle;
	color: #000000;
	text-decoration: none;
}
.menu-wrap{
	text-align: right;
}
.menu-item1 .login{
	font-size: 16px;
	line-height:1.5;
	color: #606060;
	margin: 0px;
	vertical-align: middle;
	text-decoration: none;
}
.wishlist{
	text-decoration: none;
	color: #606060;
}
.wishlist img{
	max-width:25px;
	width: 100%;
}
.cart{
	text-decoration: none;
	position: relative;
}
.badge{
	font-size: 10px;
	position: absolute;
	top: 14px;
	left: 10px;
	border-radius: 50px;
	padding: 4px 7px;
}

.Popup{
	max-width: 400px;
	width: 100%;
	display: none;
	position: absolute;
	height: auto;
	right: 28px;
	top:40px;
	z-index:999;
	transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
	background-color: #ffffff;
	border: 2px solid #cccccc;
	
}
.Popup:after {
	content: " ";
	position: absolute;
	right: 40%;
	top: -12px;
	width: 20px;
	height: 20px;
	border-top:2px solid #cccccc;
	border-bottom: 0px solid #cccccc;
	border-right: 0px solid #cccccc;
	border-left: 2px solid #cccccc;
	
	/*bottom: 100%;*/
	/* left: 50%;*/
	margin-left: -25px;
	transform: rotate(45deg);
	margin-bottom: -25px;
	background: #ffffff;
	/*border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-bottom: 15px solid black;*/
}
/*popup transition*/
a.cart:hover + .Popup, .Popup:hover {
	visibility: visible;
	transition: 0.3s all ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	display: block;
}
/*popup transition*/
.cart_title{
	padding: 10px 0;
	border-bottom: 1px solid #ebebeb;
	text-align: center;
}
.cart_title p{
	font-size: 20px;
	line-height: 1.6;
}
.item-detail{
	padding: 20px 10px;
	border-bottom: 1px solid #ebebeb;
}
.selected_item img{
	max-width: 95px;
	width: 100%;
	padding-top: 20px;
}
.p1{
	font-size: 18px;
	line-height: 1.5;
	color: #000;
	padding-bottom: 15px;
	text-align: left;
	margin: 0px;
	
}
.p2{
	font-size: 16px;
	line-height: 1.5;
	color: #000;
	padding-bottom:8px;
	text-align: left;
	margin: 0px;
	opacity: 0.7;
}
.total{
	padding: 20px 10px;
	border-bottom: 1px solid #ebebeb;
}

.sub-total{
	font-size: 17px;
	line-height: 1.5;
	color: #000;
	text-align: left;
	margin: 0px;
}
.amount{
	font-size: 21px;
	line-height: 1.5;
	color: #6c6c6c;
	text-align: right;
	margin: 0px;
}
.checkout-option{
	padding: 20px 10px;
	text-align: center;
}
.checkout-option p{
	font-size: 18px;
	line-height: 1.5;
}
.checkout-option a{
	display: inline-block;
	color: #ffffff;
	text-decoration: none;
	outline: none;
	background-color: #808080;
	text-align: center;
	padding:12px 35px;
	font-size: 18px;
	line-height: 1.5;
	margin-bottom: 20px;	
}
.checkout-option a .checkout-btn{
	display: inline-block;
	color: #ffffff;
	text-decoration: none;
	outline: none;
	background-color: #808080;
	text-align: center;
	padding:12px 35px;
	font-size: 18px;
	line-height: 1.5;
	margin-bottom: 20px;
}
a.view-bag-detail{
	font-size: 18px;
	line-height: 1.5;
	margin: 0px;
	padding-bottom: 20px;
	display: block;
}
.cart img{
	max-width: 22px;
	width: 100%;
}
.cart-popup{
	display: none;
}
.list-inline > li{
	padding: 0px;
}
#menu-btn{
	margin-right: 0px;
	margin-top: 10px;
}
.dropdown.open i.icon-arrows-down{
	top: 0px !important;
	height: 17px;
	position: inherit;
}
/*Header Section*/
/*product-detail*/
/*product-detail*/
.product-detail > p{
	line-height: 1.5;
	padding-bottom: 15px !important;
	margin-bottom: 0px;
}
.align-text{
	margin-bottom: 0px;
}
.product-detail .Produvt_Price{
	font-weight: 500;
	font-size: 25px;
	line-height: 1.5;
	margin-top: 15px;
}
.Heading{
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 20px !important;
}
.align-text{
	display: inline-block;
}
.Size-wrap, .ringsize-inner-wrap.r-size {
	display: flex;
	align-items: center;
}
.r-size{
	width: 60%;
}
.r-size .about_ring{
	width: 40% !important;
}
.r-size .selected-size{
	margin-bottom: 0px !important;
	box-shadow: none;
	border: 0px;
	position: relative;
	background-image: url(../images/Down_arrow.png); 
	background-repeat: no-repeat;
	background-position: 90%;
	background-size: 10px;
	-webkit-appearance: none;
	outline: none;
	background-position: 90%;
	padding: 6px 20px 6px 5px;
}
.r-quality{
	width: 40%;
}
.product-detail .Size-wrap .border-none p, .product-detail .Size-wrap .border-none p{
	font-size: 16px !important;
	padding-bottom: 0px;
}
.Ringsize a{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
	text-decoration:revert;
}
#myselect{
	margin:0 10px;
	font-size: 16px;
	line-height: 1.5;
	min-width: 60px;
}
#myselect option{
	background-color: #000000;
	color: #ffffff;
}

.product-detail-bottom p{
	color: #000000;
}
.Size-wrap div, .Size-wrap, .product-detail-bottom{
	border-bottom: 0px !important;
	padding: 0px !important;
}
.product-detail .number{
	padding-left: 20px !important;
	cursor: pointer;
}
.fa-minus:before, .fa-plus:before{
	font-size: 9px;
}
.number input{
	width: 30px;
	text-align: center;
	font-size: 16px;
	border:0px;
	display: inline-block;
	vertical-align: middle;
}
.number input:focus, #myselect:focus{
	outline: none;
}
.Heading{
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 20px !important;
}
.align-text{
	display: inline-block;
}
.product-detail .Size-wrap .border-none p, .product-detail .Size-wrap .border-none p{
	font-size: 16px !important;
	padding-bottom: 0px;
}
.Ringsize a{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}
#myselect{
	margin: 0 10px;
	font-size: 16px;
	line-height: 1.5;
	min-width: 60px;
}
.Size-wrap div, .Size-wrap, .product-detail-bottom{
	border-bottom: 0px !important;
	padding: 0px !important;
}
select#myselect {
	padding: 0 10px;
	display: block;
	width: 100%;
}
.Ringsize{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product-detail .Size-wrap .Quantity{
	padding-left: 15px !important;
}
.product-detail .number{
	padding-left: 20px !important;
	cursor: pointer;
}
.fa-minus:before, .fa-plus:before{
	font-size: 9px;
}
.number input{
	width: 30px;
	text-align: center;
	font-size: 16px;
	border:0px;
	display: inline-block;
	vertical-align: middle;
}
.number input:focus, #myselect:focus{
	outline: none;
}
/*Description section*/
.Description_Shipping{
	padding: 40px 0;
	border-bottom: 1px solid #f68e56;
}
.Description_Shipping .text p{
	font-size: 19px;
	text-align: center;
	line-height:1.7;
	margin-top: 50px;
}
.Description_Shipping p{
	font-size: 19px;
	text-align: center;
	line-height:1.7;
	margin-top: 50px;
}
.heads ul{
	text-align: center ;
}
.heads ul li{
	display: inline-block;
	padding: 20px 0px 10px;
	text-align: center;
	margin: 0 auto;
	font-size: 17px;
	position: relative;
	float: none;
	margin: 0 50px;
}
.heads ul li a{
	color: #757a8d;
}
.nav-tabs > li > a:hover{
	color:#000000;
	border: 0px;
	background-color: #ffffff;
}

.heads ul .head1:after{
	content: '';
	width: 100%;
	position: absolute;
	height: 4px;
	bottom: 0px;
	left:0px;
	margin: 0 auto;
	background-color: #c1c1c1;
	-webkit-transition: all 0.3s ease 0s;
}
.heads ul .head1.active:after{
	background-color: #1f1f1f;
}
/*.heads ul .head2:hover:after{
	background-color: #1f1f1f;
}
.heads ul .head2:after{
	content: '';
	width: 100%;
	position: absolute;
	height: 4px;
	margin: 0 auto;
	bottom: 0px;
	left:0px;
	background-color: #c1c1c1;
}*/
.nav-tabs{
	border: none;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
	border: none;
}
.nav-tabs > li > a {
	margin-right: 0px;
	line-height: 1.42857143;
	border: 0px ;
	border-radius:0px;
}
.ring-bottom{
	border: none;
	padding-bottom: 0px;
}
/*Description section*/

.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

/*Media Query*/
@media screen and (max-width: 1200px){
	/* .header{
		height: 100px;
	} */
	/*.sub-menu{
		top: 100px !important;
	}*/
}

@media screen and (max-width: 767px){
	.header {
		overflow: visible;
		height: 48px;
	}
	.header.menu-opened ul.menu li.menu-item:nth-child(10){
		padding-top: 0px;
	}
	.header .logo {
		padding-top: 5px;
	}
	.list-inline.Social-icon{
		margin-top: 15px;
	}
	.dropdowns .drop1{
		display: none;
	}
	.menu-wrap{
		display: flex;
		justify-content:flex-end;
	}
	.Popup{
		width: 300px;
	}
	header .nav-content {
		margin: 9px auto;
	}
	.menu-opened .cart img, .menu-opened .wishlist  img{
		filter: brightness(0) invert(1);
	}
	.header .burger-container #burger{
		margin-top: 5px;
		padding: 0px
	}
	/*	.dropdowns{
		float: right;
		width: 30%;
	}
	.top-menu{
		width: 20%;
	}*/
	.menu-item1{
		vertical-align: top;
	}
	.login::before{
		content:"\f090";
		font-weight: bold;
		padding-right: 3px;
		font-family: FontAwesome;
		left:0px;
	}
	.menu-item1 .login{
		display: none;
	}
	.Popup:after{
		right: 40px;
	}
	.Description_Shipping .text p{
		margin-top: 30px;
	}
	/* header.affix .nav-content{
		margin: 15px auto;
	} */
	.p-l-r-0 {
		padding-left:0;
		padding-right:0;
	}
	header.affix-top.menu-opened .wishlist{
		color: #cecece;
	}
	.logo-mobile img {
		opacity: 1;
		filter: brightness(0);
		filter: brightness(0) invert(1);
	}
	.logo-mobile {
		background: none;
	}
}

/*Media Query*/

.error-border {
	border : #dc3545 solid 1px !important;
}

.arm-w{
	position: relative;
}
.w-badge{
	top: 12px !important;
	left: 6px !important;
}
.single_slide{
	position: relative;
}
.add-to-wishlist{
	width: 30px;
	height: 30px;
	position: absolute;
	top: 0;
	right: 20px;
	background-image: url(../images/your-wishlist.png);
	background-size: cover;
	background-repeat: no-repeat;
	transition: all 0.4s ease;
	background-position: 0px 2px;
}
.item-selected{
	background-position: 0px -42px;
	
}
@media(max-width:1200px){
	.ringsize-inner-wrap.r-size{
		flex-wrap: wrap;
	}
	.ringsize-inner-wrap{
		width: 50% !important;
	}
	.r-size .about_ring{
		width: 100% !important;
	}
	.Size-wrap{
		align-items: baseline;
	}
}